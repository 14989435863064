import React from "react"
import UITable from "../../../lib/table-v2";
import {
    Icon,
    Menu,
    Message,
    Segment,
    Table,
    Modal,
    Loader,
    Pagination,
    Item,
    Label,
    Confirm as SemanticConfirm,
    Card, Dimmer, Accordion, TextArea, Form
} from 'semantic-ui-react'
import Button from "../../../components/Button";
import {api} from "../../../lib/axios";
import {formatDate} from "../../../helpers/dateHelper";
import {OrderStatusesHelper} from "../../../helpers/orderStatuses";
import SnackBar from "../../../components/Snack";
import MenuButton from "../../../lib/table-v2/menuButton";
import EsotiqInput from "../../../components/EsotiqInput";
import DisplayCart from "./cartDisplay";
import Products from "../../products";
import {DATA_OBJECT} from "../../../controller/dataController";
import {TranslationController} from "../../../controller/menager";
import {jsonCopy} from "../../../helpers/lib";
import TableMenu from "../../../components/TableMenu";
import Confirm from "../../../components/Confirm";
import {getCurrencyString} from "../../../lib/currency";

export default class OrderCart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cartData: null,
            completation: null,
            completationLoader: true,
            statesLoader: true,
            activeCompletation: 1,
            propositions: null,
            stockData: null,
            wasCompletationAccepted: this.props.wasCompletationAccepted,
            v2: this.props.order.orderPicker === 'V2',
            correctionDescription: null,
        }
    }

    componentDidMount() {
        this.initCart();
    }

    /**
     * For pormotion list in cart preview
    **/
    init(limit, currentPage) {
        return api().post("/api/promotions/list/get", {
            limit, currentPage, images: true, country: this.props.order.country, brand: this.props.order.brand})
            .then(r => {
                this.setState({data: r.data.data});

                return r.data.data
            })
    }

    initCart() {
        const cartData = this.props.data;

        this.setState({
            cartData: cartData
        })

        api().post("/api/orderService", {orderId: this.props.orderId, limit: 9999, currentPage: 1})
            .then(r => {
                this.setState({
                    orderServiceData: r.data.data
                })
            })

        const Collecting = () => {
            this.setState({
                collecting: true
            });

            api(true).post("/api/states/collect", {
                order: this.props.data,
                orderId: this.props.orderId,
                internalOrder: this.props.internalOrder || false,
                brand: this.props.order.brand,
                country: this.props.order.country,
                lang: this.props.order.lang,
                sources: this.props.order.sources,
                forceWarehouse: (this.props.order.additionalOptions && this.props.order.additionalOptions.forceWarehouse) ? this.props.order.additionalOptions.forceWarehouse : false,
            })
                .then(r => {
                    this.setState({
                        data: r.data.data,
                        type: r.data.type,
                        propositions: r.data.propositions,
                        notCompletedPropositions: r.data.notCompletedPropositions,
                        completationLoader: false,
                        collecting: false,
                        excludedOrderService: r.data.excludedOrderService
                    })
                });

            api().post("/api/orderService/get", {orderId: this.props.orderId})
                .then(r => {
                    this.setState({
                        completationPreview: r.data.data,
                        completationPreviewLoader: false,
                        dimmer: false
                    })
                });
        };

        let allowCollecting = this.props.order.statusCompletation && (this.props.isParlourOrder || this.props.internalOrder);

        if (this.props.isParlourOrder && this.props.order.statusCompletation === "notSolved" && !this.props.order.wasCompletationAccepted) {
            allowCollecting = false;

            Collecting();
        }

        if (allowCollecting) {
            switch (true) {
                case !this.props.order.wasCompletationAccepted && this.props.order.statusCompletation === "assigned" && this.props.order.statusSynchro === "process":
                    Collecting();

                    break;

                case this.props.order.statusCompletation === "assigned":
                    api().post("/api/orderService/get", {orderId: this.props.orderId, limit: 9999, currentPage: 1})
                        .then(r => {
                            this.setState({
                                completation: r.data.data,
                                completationLoader: false
                            })
                        });

                    break;

                case ["notSolved", "acceptedInChunk", "partialSendedToCustomer", "partialSendedToParlour"].includes(this.props.order.statusCompletation):
                    const cart = jsonCopy(this.props.order.items);
                    let isFulfilled = true;

                    if (this.props.order.orderService) {
                        this.props.order.orderService.forEach(service => {
                            service.sending.forEach(item => {
                                cart.forEach(cartItem => {
                                    if (item.sku === cartItem.sku) {
                                        cartItem.qty = cartItem.qty - item.qty;
                                    }
                                })
                            })
                        })

                        cart.forEach(cartItem => {
                            if (cartItem.qty > 0) {
                                isFulfilled = false;
                            }
                        })
                    }

                    if (!isFulfilled && !this.props.order.wasCompletationAccepted) {
                        Collecting();
                    }

                    break
            }
        } else {
            this.setState({
                completationLoader: false,
                dimmer: false

            })
        }
    }

    getStockData() {
        this.setState({
            gettingStockData: true
        })

        if (this.state.v2 && this.props.order.country === "de") {
            api().post("/api/states/raw-order-picker", {
                country: this.props.order.country,
                skuList: this.state.cartData.map(item => item.sku)
            })
            .then(r => {
                this.setState({
                    stockData: r.data.data,
                })
            })
        }  else {
        api().post("/api/states" + (this.state.v2 ? "/order-picker" : ""), {filter: [{
                label: "SKU",
                field: "sku",
                value: this.state.cartData.map(item => item.sku),
                condition: "in"
            }], limit: 99999, currentPage: 1})
            .then(r => {
                this.setState({
                    stockData: r.data.data,
                })
            })
        }
    }

    syncOrder() {
        return api().post("/api/orders/syncOrder", {
            orderId: this.props.orderId
        })
            .then(() => {
                SnackBar("Zsynchronizowano zamówienie")
            })
            .then(() => this.props.refresh())
    }

    assignOrder(notCompleted) {
        this.setState({
            dimmer: true
        });

        const completation = notCompleted ? this.state.notCompletedPropositions[this.state.activeCompletation - 1] : this.state.propositions[this.state.activeCompletation - 1];

        completation.forEach(parlour => delete parlour.state);

        api().post("/api/orderService/assign", {
            completation: notCompleted ? this.state.notCompletedPropositions[this.state.activeCompletation - 1] : this.state.propositions[this.state.activeCompletation - 1],
            orderId: this.props.orderId
        })
            .then(r => {
                if (r.data.success) {
                    api().post("/api/orders/update", {
                        order: {
                            id: this.props.orderId,
                            wasCompletationAccepted: true
                        },
                        userId: DATA_OBJECT.user_login
                    });

                    this.setState({
                        orderServiceData: null,
                        wasCompletationAccepted: true,
                        cartData: null,
                        completation: null,
                        completationLoader: true,
                        statesLoader: true,
                        activeCompletation: 1,
                        propositions: null,
                        stockData: null,
                    })

                    api().post("/api/orderService", {orderId: this.props.orderId})
                        .then(r => {
                            this.initCart();

                            this.props.refresh();

                            this.setState({
                                orderServiceData: r.data.data,
                            })
                        });

                    return SnackBar("Zamówienie zostało poprawnie przypisane do salonów.")
                } else {
                    this.setState({
                        dimmer: false
                    })

                    return SnackBar("Wystąpił błąd podczas przypisywania do salonów.", "error")
                }
            })
    }

    checkIsOrderMissing() {
        let orderService = this.state.orderServiceData

        orderService = orderService.filter(os => os.status != "rejected");

        let preperedOrder = {};

        (this.state.cartData || []).forEach(orderItem => {
            preperedOrder[orderItem.sku] = {
                qty: orderItem.qty,
                data: orderItem
            }
        });

        orderService.forEach(propItem => {
            propItem.sending.forEach(sendingItem => {
                const key = sendingItem.sku
                const value = sendingItem.qty

                if (preperedOrder[key]) {
                    preperedOrder[key].qty = preperedOrder[key].qty - value

                    if (preperedOrder[key].qty < 1) {
                        delete preperedOrder[key]
                    }
                }
            })
        })

        const preperedOrderArray = []

        for (let [key, value] of Object.entries(preperedOrder)) {
            const o = {
                ...value.data,
                qty: value.qty
            }

            preperedOrderArray.push(o)
        }

        return preperedOrderArray
    }

    getMissingItems(forceCompletation) {
        const proposition = (forceCompletation || this.state.notCompletedPropositions)[this.state.activeCompletation - 1];

        if (!proposition) {
            return this.state.cartData
        }

        let preperedOrder = {};

        (this.state.cartData || []).forEach(orderItem => {
            preperedOrder[orderItem.sku] = {
                qty: orderItem.qty,
                data: orderItem
            }
        });

        proposition.forEach(propItem => {
            for (let [key, value] of Object.entries(propItem.sending)) {
                if (preperedOrder[key]) {
                    preperedOrder[key].qty = preperedOrder[key].qty - value

                    if (preperedOrder[key].qty < 1) {
                        delete preperedOrder[key]
                    }
                }
            }
        })

        if (this.state.excludedOrderService && this.state.excludedOrderService.length) {
            this.state.excludedOrderService.forEach(propItem => {
                propItem.sending.forEach(item => {
                    if (preperedOrder[item.sku]) {
                        preperedOrder[item.sku].qty = preperedOrder[item.sku].qty - item.qty;

                        if (preperedOrder[item.sku].qty < 1) {
                            delete preperedOrder[item.sku]
                        }
                    }
                })
            })
        }

        const preperedOrderArray = []

        for (let [key, value] of Object.entries(preperedOrder)) {
            const o = {
                ...value.data,
                qty: value.qty
            }

            preperedOrderArray.push(o)
        }

        return preperedOrderArray
    }

    getPromotions(limit, page) {
        this.setState({
            promotions: null
        });

        return api().post("/api/promotions/list/get", {
            images: true,
            limit: limit || 20,
            currentPage: page || 1,
            filter: [{
                field: "state.country",
                value: "de",
                condition: "eq"
            }, {
                field: "state.brand",
                value: this.props.order.brand,
                condition: "eq"
            }]
        })
            .then(r => {
                this.setState({
                    promotions: r.data.data
                })
            })
    }

    addToBasket(item) {
        this.props.setDimmer();

        this.state.cartData = null;

        const count = this.state.cartItems || 1

        api().post("/api/orders/addToCart", {
            item: {...item, count},
            orderId: this.props.orderId,
            userId: DATA_OBJECT.user_login
        })
            //.then(() => this.syncOrder())
            .then((r) => {
                this.props.refresh()
                    .then(() => {
                        this.initCart();
                    })
            })
    }

    isCreateCorrectionButtonDisabled() {
        if (!this.displayCart) return true;
        if (!this.props.deliveryManagementProps) return true;
        if (!this.props.deliveryManagementProps.orderData.invoiceNo) return true;

        return false;
    }

    render() {
        if (this.state.notCompletedPropositions) {
            this.getMissingItems()
        }

        return (
            <React.Fragment>
                {this.state.modalOpened &&
                <Modal dimmer={"inverted"} size={"mini"} open={this.state.modalOpened} onClose={() => this.setState({modalOpened: false})}>
                    <Modal.Header>{TranslationController.translate("Wybierz rozmiar")}</Modal.Header>
                    <Modal.Content>
                        <EsotiqInput
                            label={"Ilość"}
                            placeholder={"Podaj ilość"}
                            type={"number"}
                            disablePaste={true}
                            onChange={(value) => {
                                let v = 0;

                                if (Number.isNaN(value)) {
                                    v = 1;
                                } else {
                                    if (value > 0) {
                                        v = value;
                                    } else {
                                        v = 1;
                                    }
                                }

                                this.setState({
                                    cartItems: v
                                })
                            }}
                        />
                        <EsotiqInput
                            label={"Rozmiar"}
                            options={this.state.modalOpened.variants.map((e, k) => ({
                                key: k, value: e, text: e.sku
                            }))}
                            placeholder={"Wybierz rozmiar..."}
                            onChange={(value) => {
                                const country = this.props.order.country === "ua" ? "ukr" : ["ee", "lt", "lv"].includes(this.props.order.country) ? "prib" : this.props.order.country;

                                if (!this.state.modalOpened.countries[`${this.props.order.brand}_${country}`]) {
                                    return SnackBar("Brak cen dla wybranego kraju. Nie można wybrać tego produktu.", "error")
                                }

                                this.setState({
                                    selectedVariant: {
                                        ...this.state.modalOpened,
                                        ...value,
                                        price: this.state.modalOpened.countries[`${this.props.order.brand}_${country}`] ? this.state.modalOpened.countries[`${this.props.order.brand}_${country}`].price : 999999,
                                        basePrice: this.state.modalOpened.countries[`${this.props.order.brand}_${country}`] ? this.state.modalOpened.countries[`${this.props.order.brand}_${country}`].base_price : 999999,
                                        referencePrice: this.state.modalOpened.countries[`${this.props.order.brand}_${country}`] ? this.state.modalOpened.countries[`${this.props.order.brand}_${country}`].reference_price : 999999
                                    }
                                })
                            }}
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.setState({modalOpened: false})} negative>Anuluj</Button>
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='Zapisz'
                            onClick={() => {
                                if (!this.state.selectedVariant) {
                                    return SnackBar("Nie wyrabno rozmiaru.", "error")
                                }

                                this.addToBasket(this.state.selectedVariant);
                                this.setState({modalOpened: false, selectedVariant: null, addProductModal: null});

                                return SnackBar("Dodano do koszyka")
                            }}
                        />
                    </Modal.Actions>
                </Modal>
                }

                {this.state.addProductModal &&
                    <Modal dimmer={"inverted"}
                           size={"Medium"}
                           open={this.state.addProductModal}
                           onClose={() => this.setState({addProductModal: false})}>
                        <Modal.Header></Modal.Header>
                        <Modal.Content>
                            <div style={{
                                minHeight: 300,
                                fontSize: "0.9em"
                            }}>
                                <Products simple={true} addColumns={[{
                                    label: "Dodaj",
                                    selectable: true,
                                    render: (row) => (
                                        <div onClick={() => {
                                            this.setState({
                                                modalOpened: row
                                            })
                                        }}>
                                            <Icon name={"add"}></Icon>
                                        </div>
                                    )
                                }]}/>
                            </div>
                        </Modal.Content>
                    </Modal>
                }

                {this.state.promotionModal &&
                    <Modal dimmer={"inverted"}
                           size={"Medium"}
                           open={this.state.promotionModal}
                           onClose={() => this.setState({promotionModal: false})}>
                        <Modal.Header></Modal.Header>
                        <Modal.Content>
                            <div style={{
                                minHeight: 300
                            }}>
                                {!this.state.promotions && !this.state.promotionResult &&
                                    <Loader active={true}/>
                                }

                                {this.state.promotions &&
                                    <div style={{
                                        fontSize: "0.9em"
                                    }}>
                                        <UITable
                                            dataFetch={{
                                                url: "/api/promotions/list/get",
                                                options: {limit: 25, currentPage: 1, country: this.props.order.country, brand: this.props.order.brand}
                                            }}
                                            ref={table => this.orderTable = table}
                                            provider={this}
                                            columns={[
                                                {
                                                    label: "Id",
                                                    field: "name",
                                                    center: true,
                                                    width: 1,
                                                    render: row => row["$loki"],
                                                    filters: [
                                                        {label: "Id", condition: "like", type: "text", forceField: "$loki"}
                                                    ],
                                                },
                                                {
                                                    label: "Aktywna",
                                                    field: "state.isActive",
                                                    bool: true,
                                                    center: true,
                                                    width: 1,
                                                    filters: [
                                                        {label: "Aktywna", condition: "greater", type: "toggle", forceField: "state.isActive"}
                                                    ],
                                                },
                                                {
                                                    label: "Nazwa",
                                                    field: "state.name",
                                                    width: 6,
                                                    filters: [
                                                        {label: "Nazwa", condition: "like", type: "text"}
                                                    ],
                                                },
                                                {
                                                    label: "Wybierz",
                                                    field: "products",
                                                    width: 1,
                                                    render: row => {
                                                        return (
                                                            <div style={{
                                                                cursor: "pointer"
                                                            }} onClick={() => {
                                                                this.setState({
                                                                    promotions: null
                                                                })

                                                                api().post("/api/promotions/calculate", {
                                                                    conf: row.state,
                                                                    cart: this.props.data
                                                                })
                                                                    .then((res) => {
                                                                        this.setState({
                                                                            promotionResult: res.data.result,
                                                                            promotionId: row["$loki"]
                                                                        })
                                                                    })
                                                            }}>
                                                                <i className='check icon' />
                                                            </div>
                                                        )
                                                    },
                                                    center: true
                                                },
                                            ]}
                                        />
                                    </div>
                                }

                                {this.state.promotionResult &&
                                    <div style={{
                                        fontSize: "0.9em"
                                    }}>
                                        {
                                            this.state.promotionResult.pormotionResult.type === "success" ?
                                                <div>
                                                    <div style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        width: "100%",
                                                        marginBottom: 10,
                                                        paddingBottom: 10,
                                                        borderBottom: "1px solid rgb(234 234 234)"
                                                    }}>
                                                        <Card>
                                                            <Card.Content header='Podsumowanie' />
                                                            <Card.Content>
                                                                <div className={"simulation-content-summary"}>
                                                                    <div className={"simulation-content-summary-label"}>{TranslationController.translate("Wartość produktów")}:</div>
                                                                    <div className={"simulation-content-summary-value"}>{this.state.promotionResult.beforeDiscountTotalPrice} {this.props.order.currency}</div>
                                                                </div>

                                                                <div className={"simulation-content-summary"}>
                                                                    <div className={"simulation-content-summary-label"}>{TranslationController.translate("Wartość rabatu")}:</div>
                                                                    <div className={"simulation-content-summary-value"}>{this.state.promotionResult.discountValue} {this.props.order.currency}</div>
                                                                </div>

                                                                <div className={"simulation-content-summary"}>
                                                                    <div className={"simulation-content-summary-label"}>{TranslationController.translate("Cena")}:</div>
                                                                    <div className={"simulation-content-summary-value"}>{this.state.promotionResult.totalPrice} {this.props.order.currency}</div>
                                                                </div>
                                                            </Card.Content>
                                                        </Card>
                                                    </div>

                                                    <div className={"cart-title"}>{TranslationController.translate("Koszyk po zastosowaniu promocji")}</div>

                                                    <UITable
                                                        data={this.state.promotionResult.items}
                                                        columns={[
                                                            {
                                                                label: "SKU",
                                                                field: "sku",
                                                                width: 1,
                                                                center: true,
                                                                render: row => <div className={"es-order-priceBox"}>{row.sku}</div>
                                                            },
                                                            {
                                                                label: "Ilość",
                                                                field: "basePrice",
                                                                width: 1,
                                                                center: true,
                                                                render: row => <div className={"es-order-priceBox"}>{parseFloat(row.qty)}</div>
                                                            },
                                                            {
                                                                label: "Cena podst.",
                                                                field: "basePrice",
                                                                width: 1,
                                                                render: row => <div className={"es-order-priceBox"}>{parseFloat(row.basePrice).toFixed(2)} <span>{this.props.order.currency}</span></div>
                                                            },
                                                            {
                                                                label: "Cena jedn.",
                                                                field: "originalPrice",
                                                                width: 1,
                                                                render: row => <div className={"es-order-priceBox"}>
                                                                    {parseFloat(row.basePrice).toFixed(2)} <span>{this.props.order.currency}</span>

                                                                    {row.discountString &&
                                                                        <div>
                                                                            {row.discountString}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            },
                                                            {
                                                                label: "Cena pierw.",
                                                                field: "originalPrice",
                                                                width: 1,
                                                                render: row => <div className={"es-order-priceBox"}>{parseFloat(row.beforeDiscountPrice).toFixed(2)} <span>{this.props.order.currency}</span></div>
                                                            },
                                                            {
                                                                label: "Cena zakupu",
                                                                field: "price",
                                                                width: 1,
                                                                render: row => <div className={"es-order-priceBox"}>{parseFloat((row.totalPrice || row.finalQtyPrice)).toFixed(2)} <span>{this.props.order.currency}</span></div>
                                                            },
                                                            {
                                                                label: "Zniżka",
                                                                field: "discountAmount",
                                                                width: 1,
                                                                render: row => <div className={"es-order-priceBox"}>{( parseFloat(row.beforeDiscountPrice) - parseFloat(row.totalPrice) ).toFixed(2)} <span>{this.props.order.currency}</span></div>
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                                :
                                                <div>
                                                    <Message
                                                        negative={true}
                                                        content={this.state.promotionResult.pormotionResult.info}
                                                    />
                                                </div>
                                        }
                                    </div>
                                }
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            {this.state.promotionResult && this.state.promotionResult.pormotionResult.type === "success" &&
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            dimmer: true
                                        }, () => {
                                            api().post("/api/orders/recalcCart", {
                                                orderId: this.props.orderId,
                                                cart: this.state.promotionResult.items,
                                                promotionId: this.state.promotionId,
                                                totalPrice: this.state.promotionResult.totalPrice,
                                                beforeDiscountTotalPrice: this.state.promotionResult.beforeDiscountTotalPrice,
                                                discountValue: this.state.promotionResult.discountValue
                                            })
                                                .then(() => {
                                                    this.setState({promotionModal: false, promotionResult: null, promotionId: null})

                                                    setTimeout(() => {
                                                        this.props.refresh();
                                                        this.forceUpdate();

                                                        this.setState({
                                                            dimmer: false
                                                        })
                                                    }, 3000)
                                                })
                                        })
                                    }}
                                    positive
                                >
                                    Zastosuj
                                </Button>
                            }
                            <Button onClick={() => this.setState({promotions: true, promotionResult: null, promotionId: null})}>Wróć</Button>
                            <Button onClick={() => this.setState({promotionModal: false, promotionResult: null, promotionId: null})} negative>Zamknij</Button>
                        </Modal.Actions>
                    </Modal>
                }

                <div>
                    <Menu attached='top'>
                        <TableMenu item icon='wrench' simple>
                            <TableMenu.Menu>
                            </TableMenu.Menu>
                        </TableMenu>

                        <Menu.Menu position='right'>
                            <MenuButton
                                icon={"sync"}
                                onClick={() => {
                                    Confirm("Czy na pewno chcesz zamknąć zamówienie?", null, () => {
                                        this.props.closeOrder();
                                    })
                                }}
                                label={"Zakończ zamówienie"}
                            />

                            <MenuButton
                                icon={"sync"}
                                onClick={() => {
                                    this.syncOrder()
                                }}
                                label={"Synchronizuj"}
                            />

                            <MenuButton
                                icon={"cart"}
                                onClick={() => {
                                    this.setState({
                                        addProductModal: true
                                    })
                                }}
                                label={"Dodaj produkt do zamówienia"}
                            />

                            <MenuButton
                                icon={"add"}
                                onClick={() => {
                                    this.setState({
                                        promotionModal: true
                                    });

                                    this.getPromotions()
                                }}
                                label={"Zastosuj promocję"}
                            />
                        </Menu.Menu>
                    </Menu>

                    <Segment attached='bottom'>
                        {this.state.cartData &&
                            <div>
                                <div style={{marginTop: 10}}/>

                                <div className={"order-current-completation-present"}>
                                    {/*{this.props.order.needToRePromo &&*/}
                                    {/*    <div style={{*/}
                                    {/*        marginTop: 50,*/}
                                    {/*        marginBottom: 20*/}
                                    {/*    }}>*/}
                                    {/*        <Message*/}
                                    {/*            header={"Zmieniono koszyk objęty promocją!"}*/}
                                    {/*            content={"Zastosuj promocję docelową aby ponownie przeliczyć koszyk"}*/}
                                    {/*            error*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*}*/}

                                    {this.props.order.packList &&
                                        <Message
                                            style={{
                                                marginBottom: 10,
                                                marginTop: 30
                                            }}
                                            info={true}
                                            icon='inbox'
                                            header={TranslationController.translate("Zamówienie znajduje się na liście pakowej.")}
                                            content={`${this.props.order.packListNumber ? `Nr listy: ${this.props.order.packListNumber}` : ""}${this.props.order.packListWorker ? ` Osoba zbierająca: ${this.props.order.packListWorker}` : ""}`}
                                        />
                                    }

                                    <div className={"cart-action"}>
                                    { process.env.REACT_APP_WMS_INTEGRATION === "true" &&
                                        <Button
                                            onClick={() => {
                                                Confirm("Czy na pewno chcesz wysłać komunikat WMS??", "", () => {
                                                    api().post("/api/orders/wms", {
                                                        orderId: this.props.orderId,
                                                        userId: DATA_OBJECT["user"]["login"]
                                                    })
                                                        .then((r) => {
                                                            if (r.data.error) {
                                                                return SnackBar(r.data.message, "error")
                                                            }

                                                            SnackBar("Wygenerowano komunikat WMS.");
                                                        })
                                                })
                                            }}
                                            as='div'
                                            labelPosition='right'
                                        >
                                            <Button basic color={"blue"}>
                                                <Icon name='fork' />
                                                {TranslationController.translate("Komunikat WMS")}
                                            </Button>
                                            <Label style={{
                                                fontSize: "0.9em"
                                            }} as='a' color={`blue`} pointing='left'>
                                                {TranslationController.translate("Wyślij")}
                                            </Label>
                                        </Button>
                                    }

                                        <Button
                                            onClick={() => {
                                                const order = this.props.order;

                                                order.items = this.state.cartData;

                                                this.props.reCompltation(order);
                                            }}
                                            as='div'
                                            labelPosition='right'
                                        >
                                            <Button basic color={"blue"}>
                                                <Icon name='fork' />
                                                {TranslationController.translate("Ponowna kompletacja")}
                                            </Button>
                                            <Label style={{
                                                fontSize: "0.9em"
                                            }} as='a' color={`blue`} pointing='left'>
                                                {TranslationController.translate("Uruchom")}
                                            </Label>
                                        </Button>
                                    </div>

                                    <DisplayCart
                                        deliveryManagementProps={this.props.deliveryManagementProps}
                                        setRef={ref => this.displayCart = ref}
                                        currency={this.props.order.currency}
                                        provider={this}
                                        orderId={this.props.orderId}
                                        cartData={this.state.cartData}
                                        orderData={this.props.order}
                                        orderServiceData={this.state.orderServiceData}
                                        syncOrder={this.syncOrder}
                                        useScanner={(DATA_OBJECT.user_login === 'root' || DATA_OBJECT.selected_parlour === 'VOS' || DATA_OBJECT.selected_parlour === 'E99')}
                                        refresh={() => {
                                            this.state.cartData = null;

                                            return this.props.refresh()
                                                .then(() => {
                                                    this.initCart();
                                                })
                                        }}
                                    />

                                    <div style={{
                                        display: "flex",
                                        justifyContent: "flex-end"
                                    }}>
                                        {(DATA_OBJECT.user_login === 'root' || DATA_OBJECT.selected_parlour === 'VOS' || DATA_OBJECT.selected_parlour === 'E99') &&
                                            <React.Fragment>
                                                <Button
                                                    positive={true}
                                                    disabled={DATA_OBJECT.user_login !== 'root' && (this.displayCart && !this.displayCart.state.isScannerComplete)}
                                                    style={{
                                                        marginLeft: 5
                                                    }}
                                                    onClick={() => {
                                                        if (this.props.order.invoiceNo) {
                                                            return SnackBar("Faktura do tego zamówienia została już wygenerowana.", "error");
                                                        }

                                                        Confirm("Czy na pewno chcesz wygenerować nową fakturę?", "", () => {
                                                            api().post("/api/order/createInvoice", {
                                                                orderId: this.props.orderId,
                                                                userId: DATA_OBJECT["user"]["login"]
                                                            })
                                                                .then((r) => {
                                                                    this.props.refresh();
                                                                    this.forceUpdate();

                                                                    if (r.data.error) {
                                                                        return SnackBar(r.data.message, "error")
                                                                    }

                                                                    return SnackBar("Wygenerowano nową fakturę.");
                                                                })
                                                        })
                                                    }}
                                                >
                                                    {TranslationController.translate("Wystaw fakturę")}
                                                </Button>

                                                <Button
                                                    positive={true}
                                                    disabled={this.isCreateCorrectionButtonDisabled()}
                                                    style={{
                                                        marginLeft: 5
                                                    }}
                                                    onClick={() => {
                                                        this.setState({
                                                            dimmer: true
                                                        }, () => {
                                                            this.state.cartData.map(item => item.toCorrection = 0);

                                                            api().post("/api/order/getPreviouslyCorrections", {
                                                                orderId: this.props.orderId
                                                            })
                                                                .then((r) => {
                                                                    if (r.data.error) {
                                                                        return SnackBar(r.data.message, "error")
                                                                    }

                                                                    this.setState({
                                                                        invoiceCorrection: true,
                                                                        previouslyCorrections: r.data.data,
                                                                        dimmer: false
                                                                    })
                                                                })
                                                        })
                                                    }}
                                                >
                                                    {TranslationController.translate("Wystaw korektę")}
                                                </Button>
                                            </React.Fragment>
                                        }

                                        <Button
                                            negative={true}
                                            style={{
                                                marginLeft: 5
                                            }}
                                            onClick={() => {
                                                api().post("/api/orders/updateCart", {
                                                    orderId: this.props.orderId,
                                                    cart: this.state.cartData,
                                                    userId: DATA_OBJECT["user"]["login"]
                                                })
                                                    .then(() => {
                                                        this.props.refresh();
                                                        this.forceUpdate();

                                                        SnackBar("Zaktualizowano zamówienie")
                                                    })
                                            }}
                                        >
                                            {TranslationController.translate("Przelicz koszyk ponownie")}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        }

                        {/*<div style={{height: 1, width: "100%", marginTop: 40, marginBottom: 20, background: "#ededed"}}></div>*/}

                        { !this.state.wasCompletationAccepted && this.state.collecting &&
                            <div className={"order-current-completation-present"}>
                                <div className={"cart-title"}>
                                    <Icon name={"sync"}/>

                                    {TranslationController.translate("Propozycja kompletacji")}
                                </div>

                                <div style={{height: 100}}></div>
                                <div style={{
                                    position: "relative",
                                    top: -40
                                }}>
                                    <Loader
                                        active
                                        content={TranslationController.translate("Szukam najlepszej kompletacji...")}
                                    />
                                </div>
                            </div>
                        }

                        { ( (["notSolved", "acceptedInChunk", "partialSendedToCustomer", "partialSendedToParlour"].includes(this.props.order.statusCompletation)) || this.props.order.statusSynchro === "process") && this.state.propositions &&
                        <div className={"order-current-completation-present"}>
                            <div className={"cart-title"}>
                                <Icon name={"sync"}/>

                                {TranslationController.translate("Propozycja kompletacji")}
                            </div>

                            <div>{
                                (this.state.propositions.length ?
                                    <div>
                                        <div style={{height: 5}}></div>
                                        <Message
                                            error
                                            icon='inbox'
                                            header={TranslationController.translate("Automatyczna kompletacja niemożliwa.")}
                                            content={`${TranslationController.translate("Propozycje kompletacji. Wybierz i zatwierdź z listy")} ${this.state.propositions.length} ${TranslationController.translate("możliwych rozwiązań")}.`}
                                        />

                                        <div>
                                            <Item.Group divided>
                                                <div style={{
                                                    x: "#b4e8c0",
                                                    boxShadow: this.state.activeCompletation === 1 ? "0 0 0 1px #b4e8c0 inset, 0 0 0 0 transparent" : "0 0 0 1px #f1f1f1 inset, 0 0 0 0 transparent"
                                                }} className={"prop-divided"}>
                                                    <Item>
                                                        <Table basic='very'>
                                                            <Table.Header>
                                                                <Table.Row>
                                                                    <Table.HeaderCell>{TranslationController.translate("Salon")}</Table.HeaderCell>
                                                                    <Table.HeaderCell>{TranslationController.translate("Do wysyłki")}</Table.HeaderCell>
                                                                    <Table.HeaderCell>{TranslationController.translate("Stan magazynowy")}</Table.HeaderCell>
                                                                </Table.Row>
                                                            </Table.Header>

                                                            <Table.Body>
                                                                {/*{this.state.excludedOrderService && this.state.excludedOrderService.length &&*/}

                                                                {/*this.state.excludedOrderService.map((e, k) => {*/}
                                                                {/*    return (*/}
                                                                {/*        <Table.Row positive key={k}>*/}
                                                                {/*            <Table.Cell width={2}>*/}
                                                                {/*                <Label ribbon>{TranslationController.translate("Przypisane")}</Label>*/}

                                                                {/*                <div style={{*/}
                                                                {/*                    textAlign: "center",*/}
                                                                {/*                    marginBottom: 4*/}
                                                                {/*                }}>*/}
                                                                {/*                    <Icon name='checkmark' />*/}
                                                                {/*                </div>*/}
                                                                {/*                <Label>*/}
                                                                {/*                    {e.parlour}*/}
                                                                {/*                </Label>*/}
                                                                {/*            </Table.Cell>*/}

                                                                {/*            <Table.Cell>*/}
                                                                {/*                <div className={"prop-divided divided-selected"}>*/}
                                                                {/*                    <Table basic='very'>*/}
                                                                {/*                        <Table.Header>*/}
                                                                {/*                            <Table.Row>*/}
                                                                {/*                                <Table.HeaderCell>{TranslationController.translate("SKU")}</Table.HeaderCell>*/}
                                                                {/*                                <Table.HeaderCell>{TranslationController.translate("Ilość")}</Table.HeaderCell>*/}
                                                                {/*                            </Table.Row>*/}
                                                                {/*                        </Table.Header>*/}

                                                                {/*                        <Table.Body>*/}
                                                                {/*                            {e.sending.map((item, index) => {*/}
                                                                {/*                                return (*/}
                                                                {/*                                    <Table.Row key={index}>*/}
                                                                {/*                                        <Table.Cell width={3}>*/}
                                                                {/*                                            {item.sku}*/}
                                                                {/*                                        </Table.Cell>*/}
                                                                {/*                                        <Table.Cell width={2}>*/}
                                                                {/*                                            {item.qty}*/}
                                                                {/*                                        </Table.Cell>*/}
                                                                {/*                                    </Table.Row>*/}
                                                                {/*                                )*/}
                                                                {/*                            })}*/}
                                                                {/*                        </Table.Body>*/}
                                                                {/*                    </Table>*/}
                                                                {/*                </div>*/}
                                                                {/*            </Table.Cell>*/}

                                                                {/*            <Table.Cell>*/}
                                                                {/*                <div className={"prop-divided"}>*/}
                                                                {/*                    ---*/}
                                                                {/*                </div>*/}
                                                                {/*            </Table.Cell>*/}
                                                                {/*        </Table.Row>*/}
                                                                {/*    )*/}
                                                                {/*})*/}
                                                                {/*}*/}

                                                                {this.state.propositions[this.state.activeCompletation - 1].map((prop, key) => {
                                                                    return (
                                                                        <Table.Row key={key}>
                                                                            <Table.Cell width={2}>
                                                                                <Label key={key + key}>
                                                                                    {prop.parlour}
                                                                                </Label>
                                                                            </Table.Cell>

                                                                            <Table.Cell>
                                                                                <div className={"prop-divided divided-selected"}>
                                                                                    <Table basic='very'>
                                                                                        <Table.Header>
                                                                                            <Table.Row>
                                                                                                <Table.HeaderCell>{TranslationController.translate("SKU")}</Table.HeaderCell>
                                                                                                <Table.HeaderCell>{TranslationController.translate("Ilość")}</Table.HeaderCell>
                                                                                            </Table.Row>
                                                                                        </Table.Header>

                                                                                        <Table.Body>
                                                                                            {prop.sending && Object.keys(prop.sending).map((key, index) => {
                                                                                                return (
                                                                                                    <Table.Row key={index}>
                                                                                                        <Table.Cell width={3}>
                                                                                                            {key}
                                                                                                        </Table.Cell>
                                                                                                        <Table.Cell width={2}>
                                                                                                            {prop.sending[key]}
                                                                                                        </Table.Cell>
                                                                                                    </Table.Row>
                                                                                                )
                                                                                            })}
                                                                                        </Table.Body>
                                                                                    </Table>
                                                                                </div>
                                                                            </Table.Cell>

                                                                            <Table.Cell>
                                                                                <div className={"prop-divided"}>
                                                                                    <Table basic='very'>
                                                                                        <Table.Header>
                                                                                            <Table.Row>
                                                                                                <Table.HeaderCell>{TranslationController.translate("SKU")}</Table.HeaderCell>
                                                                                                <Table.HeaderCell>{TranslationController.translate("Stan")}</Table.HeaderCell>
                                                                                            </Table.Row>
                                                                                        </Table.Header>

                                                                                        <Table.Body>
                                                                                            {prop.canSend.map((canSend, canSendKey) => {
                                                                                                return (
                                                                                                    <Table.Row key={canSendKey}>
                                                                                                        <Table.Cell width={3}>
                                                                                                            {canSend.sku}
                                                                                                        </Table.Cell>
                                                                                                        <Table.Cell width={2}>
                                                                                                            {canSend.qty}
                                                                                                        </Table.Cell>
                                                                                                    </Table.Row>
                                                                                                )
                                                                                            })}
                                                                                        </Table.Body>
                                                                                    </Table>
                                                                                </div>
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                    )
                                                                })}
                                                            </Table.Body>
                                                        </Table>

                                                        <div>
                                                            <Button
                                                                primary
                                                                onClick={() => {
                                                                    this.setState({
                                                                        confirm: true
                                                                    })
                                                                }}
                                                            >
                                                                {TranslationController.translate("Wybierz i przypisz kompletację")}
                                                            </Button>
                                                            <div style={{height: 5}}></div>
                                                        </div>

                                                        {/*<div className={"cart-missing-items"}>*/}
                                                        {/*    <div className={"cart-title"}>*/}
                                                        {/*        <Icon style={{color: "red"}} name={"ban"}/>*/}

                                                        {/*        {TranslationController.translate("Brakujące towary względem propozycji kompletacji")}*/}
                                                        {/*    </div>*/}

                                                        {/*    <DisplayCart*/}
                                                        {/*        currency={this.props.order.currency}*/}
                                                        {/*        provider={this}*/}
                                                        {/*        orderId={this.props.orderId}*/}
                                                        {/*        orderData={this.props.order}*/}
                                                        {/*        cartData={this.getMissingItems([this.state.propositions[this.state.activeCompletation - 1]])}*/}
                                                        {/*        noDelete={true}*/}
                                                        {/*        noImages={true}*/}
                                                        {/*        editableBlocked={true}*/}
                                                        {/*        refresh={() => {*/}
                                                        {/*            this.state.cartData = null;*/}

                                                        {/*            return this.props.refresh()*/}
                                                        {/*                .then(() => {*/}
                                                        {/*                    this.initCart();*/}
                                                        {/*                })*/}
                                                        {/*        }}*/}
                                                        {/*    />*/}

                                                        {/*    <div className={"cart-missing-items-icon"}>*/}
                                                        {/*        <Icon name={"arrows alternate vertical"}/>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}

                                                        <SemanticConfirm
                                                            dimmer={"inverted"}
                                                            open={this.state.confirm}
                                                            content='Czy na pewno chcesz przypisać wybraną kompletację?'
                                                            onCancel={() => this.setState({confirm: false})}
                                                            onConfirm={() => {
                                                                this.setState({
                                                                    confirm: false,
                                                                })

                                                                this.assignOrder()
                                                            }}
                                                        />

                                                        {this.state.activeCompletation === 1 &&
                                                        <Item.Extra>
                                                            <Icon color='green' name='check' /> {TranslationController.translate("Zalecana propozycja kompletacji")}
                                                        </Item.Extra>
                                                        }
                                                    </Item>
                                                </div>
                                            </Item.Group>

                                            <Pagination
                                                onPageChange={(e, page) => {
                                                    this.setState({
                                                        activeCompletation: page.activePage
                                                    })
                                                }
                                                }
                                                defaultActivePage={this.state.activeCompletation}
                                                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                                                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                                                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                                                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                                nextItem={{ content: <Icon name='angle right' />, icon: true }}
                                                totalPages={this.state.propositions.length}
                                            />
                                        </div>
                                    </div> :
                                    !this.state.completationLoader &&
                                    <div>
                                        <div style={{height: 5}}></div>

                                        <Message
                                            error
                                            icon='inbox'
                                            header={TranslationController.translate("Kompletacja towaru niemożliwa. Brak towaru w magazynach.")}
                                            content={(this.state.notCompletedPropositions && this.state.notCompletedPropositions.length) ? TranslationController.translate("Dostępne są niepełne rozwiązania.") : TranslationController.translate("Spróbuj ponownie później lub zmień ilość produktów w koszyku.")}
                                        />

                                        {/*<div className={"cart-missing-items"}>*/}
                                        {/*    <div className={"cart-title"}>*/}
                                        {/*        <Icon style={{color: "red"}} name={"ban"}/>*/}

                                        {/*        {TranslationController.translate("Brakujące towary względem propozycji kompletacji")}*/}
                                        {/*    </div>*/}

                                        {/*    <DisplayCart*/}
                                        {/*        currency={this.props.order.currency}*/}
                                        {/*        provider={this}*/}
                                        {/*        orderId={this.props.orderId}*/}
                                        {/*        orderData={this.props.order}*/}
                                        {/*        cartData={this.getMissingItems()}*/}
                                        {/*        noDelete={true}*/}
                                        {/*        editableBlocked={true}*/}
                                        {/*        refresh={() => {*/}
                                        {/*            this.state.cartData = null;*/}

                                        {/*            return this.props.refresh()*/}
                                        {/*                .then(() => {*/}
                                        {/*                    this.initCart();*/}
                                        {/*                })*/}
                                        {/*        }}*/}
                                        {/*    />*/}

                                        {/*    <div className={"cart-missing-items-icon"}>*/}
                                        {/*        <Icon name={"arrows alternate vertical"}/>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        {this.state.notCompletedPropositions && this.state.notCompletedPropositions.length ?
                                            <div>
                                                <Item.Group divided>
                                                    <div style={{
                                                        x: "#b4e8c0",
                                                        boxShadow: this.state.activeCompletation === 1 ? "0 0 0 1px #b4e8c0 inset, 0 0 0 0 transparent" : "0 0 0 1px #f1f1f1 inset, 0 0 0 0 transparent"
                                                    }} className={"prop-divided"}>
                                                        <Item>
                                                            <Table basic='very'>
                                                                <Table.Header>
                                                                    <Table.Row>
                                                                        <Table.HeaderCell>Salon</Table.HeaderCell>
                                                                        <Table.HeaderCell>Do wysyłki</Table.HeaderCell>
                                                                        <Table.HeaderCell>Stan magazynowy</Table.HeaderCell>
                                                                    </Table.Row>
                                                                </Table.Header>

                                                                {this.state.notCompletedPropositions[this.state.activeCompletation - 1].map((prop, key) => {
                                                                    return (
                                                                        <Table.Row key={key}>
                                                                            <Table.Cell width={2}>
                                                                                <Label key={key + key}>
                                                                                    {prop.parlour}
                                                                                </Label>
                                                                            </Table.Cell>

                                                                            <Table.Cell>
                                                                                <div className={"prop-divided divided-selected"}>
                                                                                    <Table basic='very'>
                                                                                        <Table.Header>
                                                                                            <Table.Row>
                                                                                                <Table.HeaderCell>SKU</Table.HeaderCell>
                                                                                                <Table.HeaderCell>Ilość</Table.HeaderCell>
                                                                                            </Table.Row>
                                                                                        </Table.Header>

                                                                                        <Table.Body>
                                                                                            {Object.keys(prop.sending).map((key, index) => {
                                                                                                return (
                                                                                                    <Table.Row key={index}>
                                                                                                        <Table.Cell width={3}>
                                                                                                            {key}
                                                                                                        </Table.Cell>
                                                                                                        <Table.Cell width={2}>
                                                                                                            {prop.sending[key]}
                                                                                                        </Table.Cell>
                                                                                                    </Table.Row>
                                                                                                )
                                                                                            })}
                                                                                        </Table.Body>
                                                                                    </Table>
                                                                                </div>
                                                                            </Table.Cell>

                                                                            <Table.Cell>
                                                                                <div className={"prop-divided"}>
                                                                                    <Table basic='very'>
                                                                                        <Table.Header>
                                                                                            <Table.Row>
                                                                                                <Table.HeaderCell>{TranslationController.translate("SKU")}</Table.HeaderCell>
                                                                                                <Table.HeaderCell>{TranslationController.translate("Stan")}</Table.HeaderCell>
                                                                                            </Table.Row>
                                                                                        </Table.Header>

                                                                                        <Table.Body>
                                                                                            {prop.canSend.map((canSend, canSendKey) => {
                                                                                                return (
                                                                                                    <Table.Row key={canSendKey}>
                                                                                                        <Table.Cell width={3}>
                                                                                                            {canSend.sku}
                                                                                                        </Table.Cell>
                                                                                                        <Table.Cell width={2}>
                                                                                                            {canSend.qty}
                                                                                                        </Table.Cell>
                                                                                                    </Table.Row>
                                                                                                )
                                                                                            })}
                                                                                        </Table.Body>
                                                                                    </Table>
                                                                                </div>
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                    )
                                                                })}

                                                            </Table>

                                                            <div>
                                                                <Button
                                                                    primary
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            confirm: true
                                                                        })
                                                                    }}
                                                                >
                                                                    {TranslationController.translate("Wybierz i przypisz kompletację")}
                                                                </Button>
                                                                <div style={{height: 5}}></div>
                                                            </div>

                                                            <SemanticConfirm
                                                                dimmer={"inverted"}
                                                                open={this.state.confirm}
                                                                content='Czy na pewno chcesz przypisać wybraną kompletację?'
                                                                onCancel={() => this.setState({confirm: false})}
                                                                onConfirm={() => {
                                                                    this.setState({
                                                                        confirm: false
                                                                    })

                                                                    this.assignOrder(true)
                                                                }}
                                                            />

                                                            {this.state.activeCompletation === 1 &&
                                                            <Item.Extra>
                                                                <Icon color='green' name='check' /> {TranslationController.translate("Zalecana propozycja kompletacji")}
                                                            </Item.Extra>
                                                            }
                                                        </Item>
                                                    </div>
                                                </Item.Group>

                                                <Pagination
                                                    onPageChange={(e, page) => {
                                                        this.setState({
                                                            activeCompletation: page.activePage
                                                        })
                                                    }
                                                    }
                                                    defaultActivePage={this.state.activeCompletation}
                                                    ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                                                    firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                                                    lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                                                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                                    nextItem={{ content: <Icon name='angle right' />, icon: true }}
                                                    totalPages={this.state.notCompletedPropositions.length}
                                                />
                                            </div> : <div>
                                                <Message
                                                    warning={true}
                                                    content={TranslationController.translate("Brak wyników propozycji kompletacji")}
                                                />
                                            </div>
                                        }
                                </div>)
                            }</div>
                        </div>
                        }

                        <React.Fragment>
                            {this.state.orderServiceData ?
                                <React.Fragment>
                                    {/*<div style={{height: 1, width: "100%", marginTop: 40, marginBottom: 40, background: "#ededed"}}></div>*/}

                                    <div className={"order-current-completation-present"}>
                                        <div className={"cart-title"}>
                                            <Icon name={"warehouse"}/>

                                            {TranslationController.translate("Przebieg i stan kompletacji w salonach")}
                                        </div>

                                        {this.checkIsOrderMissing().length ?
                                            <div style={{marginTop: 10}} className={"cart-missing-items"}>
                                                <div className={"cart-title"}>
                                                    <Icon style={{color: "red"}} name={"ban"}/>

                                                    {TranslationController.translate("Brakujące towary w bieżącej kompletacji")}
                                                </div>

                                                <DisplayCart
                                                    currency={this.props.order.currency}
                                                    provider={this}
                                                    orderId={this.props.orderId}
                                                    orderData={this.props.order}
                                                    cartData={this.checkIsOrderMissing()}
                                                    noImages={true}
                                                    noDelete={true}
                                                    editableBlocked={true}
                                                    refresh={() => {
                                                        this.state.cartData = null;

                                                        return this.props.refresh()
                                                            .then(() => {
                                                                this.initCart();
                                                            })
                                                    }}
                                                />

                                                <div className={"cart-missing-items-icon"}>
                                                    <Icon name={"arrows alternate vertical"}/>
                                                </div>
                                            </div> : <React.Fragment/>
                                        }
                                        {(this.state.orderServiceData && this.state.orderServiceData.length)
                                            ?
                                            <UITable
                                                data={
                                                    this.state.orderServiceData
                                                        .filter(service => {
                                                            let result = false;

                                                            service.sending.forEach(item => item.qty > 0 ? result = true : false);

                                                            return result
                                                        })
                                                }
                                                columns={[
                                                    {
                                                        field: "order",
                                                        label: "Zamówienie",
                                                        render: row => (
                                                            <div>
                                                                {row.order.id || row.orderId}
                                                            </div>
                                                        )
                                                    },
                                                    {
                                                        field: "chunks",
                                                        label: "Magazyn",
                                                        render: row => (
                                                            <div>
                                                                {row.parlour}
                                                            </div>
                                                        )
                                                    },
                                                    {
                                                        field: "chunks",
                                                        label: "Status",
                                                        render: row => (
                                                            <div>
                                                                <div className={"es-order-satus-display"}><span style={{background: new OrderStatusesHelper().getColor(row.status), color: new OrderStatusesHelper().getFontColor(row.status)}} className={"value"}>{new OrderStatusesHelper().getLabel(row.status)}</span></div>

                                                                {(row.status === "rejected" && DATA_OBJECT["user"]["login"] === "root") && <React.Fragment>
                                                                    <div>
                                                                        <Button
                                                                            primary={true}
                                                                            onClick={() => {
                                                                                api().post("/api/orderService/reject-again", {row})
                                                                                    .then(r => {
                                                                                        window.location.reload();
                                                                                    })
                                                                            }}
                                                                        >
                                                                            Odrzuć ponownie
                                                                        </Button>
                                                                    </div>
                                                                </React.Fragment>}
                                                            </div>
                                                        )
                                                    },
                                                    {
                                                        field: "chunks",
                                                        label: "Do wysyłki",
                                                        render: row => (
                                                            <div>

                                                                <Table compact>
                                                                    <Table.Body>
                                                                        {row.sending.map((item, key) => {
                                                                            return (
                                                                                <Table.Row key={key}>
                                                                                    <Table.Cell width={12}>
                                                                                        <div style={{position: "relative", top: -2}}>
                                                                                            {item.sku}
                                                                                            {item.status &&
                                                                                            item.status === "rejected" ? <div style={{background: "#f76364"}} className={"order-management-status"}>Odrzucono ({item.rejectedCount} z {item.beforeRejectedQty} szt)</div> : item.status === "canceled" ? <span style={{background: "#409ff7"}} className={"order-management-status"}>Anulowano</span> : ""
                                                                                            }
                                                                                        </div>
                                                                                    </Table.Cell>
                                                                                    <Table.Cell>Do wysyłki: {item.qty} szt.</Table.Cell>
                                                                                </Table.Row>
                                                                            )
                                                                        })}
                                                                    </Table.Body>
                                                                </Table>

                                                            </div>
                                                        )
                                                    },
                                                    {
                                                        field: "order",
                                                        label: "Utworzono",
                                                        render: row => (
                                                            <div>
                                                                {formatDate(row.created).dateTimeSystem}
                                                            </div>
                                                        )
                                                    },
                                                    {
                                                        field: "order",
                                                        label: "Zaktualizowano",
                                                        render: row => (
                                                            <div>
                                                                {formatDate(row.updated).dateTimeSystem}
                                                            </div>
                                                        )
                                                    },
                                                    {
                                                        field: "order",
                                                        label: "List przewozowy",
                                                        render: row => (
                                                            <div>
                                                                {(row.pickupNumber && row.status === "send")
                                                                ? <a href={row.provider === "gls" ? `https://gls-group.com/PL/pl/sledzenie-paczek?match=${row.pickupNumber}` : `https://tracktrace.dpd.com.pl/parcelDetails?typ=1&p1=${row.pickupNumber}`} target={"_blank"} className={"order-delivery-warpper-value"}>{row.pickupNumber}</a>
                                                                : "---"}
                                                            </div>
                                                        )
                                                    }
                                                ]}
                                            />
                                            :
                                            <div className={"no-completation-begun-info"}>
                                                <Icon name={"sync"}/>

                                                {TranslationController.translate("Żadne towary nie zostały jeszcze przypisane do kompletacji.")}
                                            </div>
                                        }
                                    </div>
                                </React.Fragment>
                                :
                                <div>
                                    <div className={"order-current-completation-present"}>
                                        <div className={"cart-title"}>
                                            <Icon name={"sync"}/>

                                            {TranslationController.translate("Przebieg i stan kompletacji w salonach")}
                                        </div>

                                        <div style={{height: 100}}></div>
                                        <div style={{
                                            position: "relative",
                                            top: -40
                                        }}>
                                            <Loader
                                                active
                                                content={TranslationController.translate("Ładuje stan bieżącej kompletacji...")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </React.Fragment>

                        {(!this.state.stockData && !this.state.gettingStockData) &&
                            <div className={"c-sl-wrapper"}>
                                <div className={"cart-title"}>
                                    <Icon name={"warehouse"}/>

                                    {TranslationController.translate("Stany magazynowe")}
                                </div>

                                <Button
                                    primary={true}
                                    onClick={() => this.getStockData()}
                                >
                                    Pobierz stany magazynowe
                                </Button>
                            </div>

                        }

                        {this.state.gettingStockData &&
                            <React.Fragment>
                                {this.state.stockData ?
                                    <div>
                                        <div className={"order-current-completation-present"}>
                                            <div className={"cart-title"}>
                                                <Icon name={"sync"}/>

                                                {TranslationController.translate("Dostępność towaru")}
                                            </div>

                                            <UITable
                                                data={this.state.stockData}
                                                showPagination={false}
                                                rowStyle={(row) => {
                                                    if (!this.state.v2 || this.props.order.country !== "de") return null;

                                                    const cartItem = this.state.cartData.find(item => item.sku === row.productSku);

                                                    return ((row.reservedQuantity - Math.abs(row.quantityDebt)) < cartItem.quantity ? {
                                                        backgroundColor: "#ffd9d9"
                                                    } : null)
                                                }}
                                                columns={(this.state.v2 && this.props.order.country === "de") ? [
                                                    {
                                                        label: "SKU",
                                                        field: "productSku"
                                                    },
                                                    {
                                                        label: "Magazyn",
                                                        field: "warehouseId"
                                                    },
                                                    {
                                                        label: "Stan magazynowy",
                                                        field: "quantity"
                                                    },
                                                    {
                                                        label: "Rezerwacja",
                                                        field: "reservedQuantity"
                                                    },
                                                    {
                                                        label: "Dług",
                                                        field: "quantityDebt"
                                                    },
                                                ] : [
                                                    {
                                                        label: "SKU",
                                                        field: "sku",
                                                        filters: [
                                                            {label: "SKU", condition: "in", type: "text"}
                                                        ]
                                                    },
                                                    {
                                                        label: "Stan",
                                                        width: 1,
                                                        field: "allState",
                                                        render: row =>
                                                            <div
                                                                style={{
                                                                    position: "relative",
                                                                }}
                                                            >
                                                                <div>
                                                                    {DATA_OBJECT["user"]["login"] !== "root" && row.allState < 0 ? 0 : row.allState}
                                                                </div>
                                                            </div>,
                                                    },
                                                    {
                                                        label: "Sklep",
                                                        field: this.state.v2 ? "e99" : "k99",
                                                        render: row =>
                                                            {
                                                                const warehouseId = this.state.v2 ? 'e99' : 'k99'
                                                                return <div
                                                                    style={{ position: "relative" }}
                                                                >
                                                                    <div onClick={() => this.setState({ modalOpened: { row, warehouse: warehouseId} })} className={"states-correction-table-display"}>{row[warehouseId].correction}</div>

                                                                    {DATA_OBJECT["user"]["login"] !== "root" && row[warehouseId].state < 0 ? 0 : row[warehouseId].state}

                                                                    {row[warehouseId].blocked ?
                                                                        <div style={{
                                                                            marginTop: 3,
                                                                            display: "flex"
                                                                        }}>
                                                                            <div style={{
                                                                                fontSize: "0.9em",
                                                                                color: "#006b86",
                                                                                marginRight: 1
                                                                            }}>
                                                                                {TranslationController.translate("Zablk.")} (System):
                                                                            </div>

                                                                            <div style={{
                                                                                fontSize: "0.8em",
                                                                                color: "#575757",
                                                                                fontWeight: 600
                                                                            }}>
                                                                                {row[warehouseId].blocked}
                                                                            </div>
                                                                        </div>
                                                                        : <React.Fragment />}

                                                                    {row[warehouseId].frontendBlocked ?
                                                                        <div style={{
                                                                            marginTop: 3,
                                                                            display: "flex"
                                                                        }}>
                                                                            <div style={{
                                                                                fontSize: "0.9em",
                                                                                color: "#006b86",
                                                                                marginRight: 1
                                                                            }}>
                                                                                {TranslationController.translate("Zablk.")} (Frontend):
                                                                            </div>

                                                                            <div style={{
                                                                                fontSize: "0.8em",
                                                                                color: "#575757",
                                                                                fontWeight: 600
                                                                            }}>
                                                                                {row[warehouseId].frontendBlocked}
                                                                            </div>
                                                                        </div>
                                                                        : <React.Fragment />}
                                                                </div>;
                                                            },
                                                        filters: [
                                                            {label: "Więcej niż", condition: "greater", type: "text", forceField: "qty", objectField: this.state.v2 ? "E99" : "K99"},
                                                            {label: "Mniej niż", condition: "less", type: "text", forceField: "qty", objectField: this.state.v2 ? "E99" : "K99"},
                                                            {label: "Pomiędzy", condition: "between", type: "between", forceField: "qty", objectField: this.state.v2 ? "E99" : "K99"}
                                                        ],
                                                        onClick: row => {
                                                            if (this.state.modalOpened) {
                                                                return false
                                                            }

                                                            this.setState({
                                                                dimmer: true
                                                            })

                                                            api().post("/api/states/getByIndex", {index: [{sku: row.sku}]})
                                                                .then(r => {
                                                                    if (r.data) {
                                                                        if (r.data.data[0] && r.data.data[0].state) {
                                                                            this.setState({
                                                                                dimmer: false,
                                                                                warehouseState: r.data.data[0].state.filter(e => e.parlour === this.state.v2 ? "E99" : "K99")
                                                                            })
                                                                        }
                                                                    }
                                                                })
                                                        }
                                                    },
                                                    {
                                                        label: "Główny",
                                                        field: "157",
                                                        render: row => <div style={{position: "relative"}}>
                                                            <div onClick={() => this.setState({modalOpened: {row, warehouse: "157"}})} className={"states-correction-table-display"}>{row["157"].correction}</div>
                                                            {DATA_OBJECT["user"]["login"] !== "root" && row["157"].state < 0 ? 0 : row["157"].state}

                                                            {row["157"].blocked ?
                                                                <div style={{
                                                                    marginTop: 3,
                                                                    display: "flex"
                                                                }}>
                                                                    <div style={{
                                                                        fontSize: "0.9em",
                                                                        color: "#006b86",
                                                                        marginRight: 1
                                                                    }}>
                                                                        {TranslationController.translate("Zablk.")} (System):
                                                                    </div>

                                                                    <div style={{
                                                                        fontSize: "0.8em",
                                                                        color: "#575757",
                                                                        fontWeight: 600
                                                                    }}>
                                                                        {row["157"].blocked}
                                                                    </div>
                                                                </div>
                                                                : <React.Fragment/>
                                                            }

                                                            {row["157"].frontendBlocked ?
                                                                <div style={{
                                                                    marginTop: 3,
                                                                    display: "flex"
                                                                }}>
                                                                    <div style={{
                                                                        fontSize: "0.9em",
                                                                        color: "#006b86",
                                                                        marginRight: 1
                                                                    }}>
                                                                        {TranslationController.translate("Zablk.")} (Frontend):
                                                                    </div>

                                                                    <div style={{
                                                                        fontSize: "0.8em",
                                                                        color: "#575757",
                                                                        fontWeight: 600
                                                                    }}>
                                                                        {row["157"].frontendBlocked}
                                                                    </div>
                                                                </div>
                                                                : <React.Fragment/>
                                                            }
                                                        </div>,
                                                        filters: [
                                                            {label: "Więcej niż", condition: "greater", type: "text", forceField: "qty", objectField: "157"},
                                                            {label: "Mniej niż", condition: "less", type: "text", forceField: "qty", objectField: "157"},
                                                            {label: "Pomiędzy", condition: "between", type: "between", forceField: "qty", objectField: "157"}
                                                        ],
                                                        onClick: row => {
                                                            if (this.state.modalOpened) {
                                                                return false
                                                            }

                                                            this.setState({
                                                                dimmer: true
                                                            })

                                                            api().post("/api/states/getByIndex", {index: [{sku: row.sku}]})
                                                                .then(r => {
                                                                    if (r.data) {
                                                                        if (r.data.data[0] && r.data.data[0].state) {
                                                                            this.setState({
                                                                                dimmer: false,
                                                                                warehouseState: r.data.data[0].state.filter(e => e.parlour === "157")
                                                                            })
                                                                        }
                                                                    }
                                                                })
                                                        }
                                                    },
                                                    {
                                                        label: "VOS",
                                                        field: "vos",
                                                        render: row => <div style={{position: "relative"}}>
                                                            <div onClick={() => this.setState({modalOpened: {row, warehouse: "vos"}})} className={"states-correction-table-display"}>{row["vos"].correction}</div>
                                                            {DATA_OBJECT["user"]["login"] !== "root" && row["vos"].state < 0 ? 0 : row["vos"].state}

                                                            {row["vos"].blocked ?
                                                                <div style={{
                                                                    marginTop: 3,
                                                                    display: "flex"
                                                                }}>
                                                                    <div style={{
                                                                        fontSize: "0.9em",
                                                                        color: "#006b86",
                                                                        marginRight: 1
                                                                    }}>
                                                                        {TranslationController.translate("Zablk.")} (System):
                                                                    </div>

                                                                    <div style={{
                                                                        fontSize: "0.8em",
                                                                        color: "#575757",
                                                                        fontWeight: 600
                                                                    }}>
                                                                        {row["vos"].blocked}
                                                                    </div>
                                                                </div>
                                                                : <React.Fragment/>
                                                            }

                                                            {row["vos"].frontendBlocked ?
                                                                <div style={{
                                                                    marginTop: 3,
                                                                    display: "flex"
                                                                }}>
                                                                    <div style={{
                                                                        fontSize: "0.9em",
                                                                        color: "#006b86",
                                                                        marginRight: 1
                                                                    }}>
                                                                        {TranslationController.translate("Zablk.")} (Frontend):
                                                                    </div>

                                                                    <div style={{
                                                                        fontSize: "0.8em",
                                                                        color: "#575757",
                                                                        fontWeight: 600
                                                                    }}>
                                                                        {row["vos"].frontendBlocked}
                                                                    </div>
                                                                </div>
                                                                : <React.Fragment/>
                                                            }
                                                        </div>,
                                                        filters: [
                                                            {label: "Więcej niż", condition: "greater", type: "text", forceField: "qty", objectField: "VOS"},
                                                            {label: "Mniej niż", condition: "less", type: "text", forceField: "qty", objectField: "VOS"},
                                                            {label: "Pomiędzy", condition: "between", type: "between", forceField: "qty", objectField: "VOS"}
                                                        ],
                                                        onClick: row => {
                                                            if (this.state.modalOpened) {
                                                                return false
                                                            }

                                                            this.setState({
                                                                dimmer: true
                                                            })

                                                            api().post("/api/states/getByIndex", {index: [{sku: row.sku}]})
                                                                .then(r => {
                                                                    if (r.data) {
                                                                        if (r.data.data[0] && r.data.data[0].state) {
                                                                            this.setState({
                                                                                dimmer: false,
                                                                                warehouseState: r.data.data[0].state.filter(e => e.parlour === "VOS")
                                                                            })
                                                                        }
                                                                    }
                                                                })
                                                        }
                                                    },
                                                    {
                                                        label: "Niemcy",
                                                        field: "de",
                                                        render: row => <div style={{position: "relative"}}>
                                                            <div onClick={() => this.setState({modalOpened: {row, warehouse: "de"}})} className={"states-correction-table-display"}>{row["de"].correction}</div>
                                                            {DATA_OBJECT["user"]["login"] !== "root" && row["de"].state < 0 ? 0 : row["de"].state}

                                                            {row["de"].blocked ?
                                                                <div style={{
                                                                    marginTop: 3,
                                                                    display: "flex"
                                                                }}>
                                                                    <div style={{
                                                                        fontSize: "0.9em",
                                                                        color: "#006b86",
                                                                        marginRight: 1
                                                                    }}>
                                                                        {TranslationController.translate("Zablk.")} (System):
                                                                    </div>

                                                                    <div style={{
                                                                        fontSize: "0.8em",
                                                                        color: "#575757",
                                                                        fontWeight: 600
                                                                    }}>
                                                                        {row["de"].blocked}
                                                                    </div>
                                                                </div>
                                                                : <React.Fragment/>
                                                            }

                                                            {row["de"].frontendBlocked ?
                                                                <div style={{
                                                                    marginTop: 3,
                                                                    display: "flex"
                                                                }}>
                                                                    <div style={{
                                                                        fontSize: "0.9em",
                                                                        color: "#006b86",
                                                                        marginRight: 1
                                                                    }}>
                                                                        {TranslationController.translate("Zablk.")} (Frontend):
                                                                    </div>

                                                                    <div style={{
                                                                        fontSize: "0.8em",
                                                                        color: "#575757",
                                                                        fontWeight: 600
                                                                    }}>
                                                                        {row["de"].frontendBlocked}
                                                                    </div>
                                                                </div>
                                                                : <React.Fragment/>
                                                            }
                                                        </div>,
                                                        filters: [
                                                            {label: "Więcej niż", condition: "greater", type: "text", forceField: "qty", objectField: "DE"},
                                                            {label: "Mniej niż", condition: "less", type: "text", forceField: "qty", objectField: "DE"},
                                                            {label: "Pomiędzy", condition: "between", type: "between", forceField: "qty", objectField: "DE"}
                                                        ],
                                                        onClick: row => {
                                                            if (this.state.modalOpened) {
                                                                return false
                                                            }

                                                            this.setState({
                                                                dimmer: true
                                                            })

                                                            api().post("/api/states/getByIndex", {index: [{sku: row.sku}]})
                                                                .then(r => {
                                                                    if (r.data) {
                                                                        if (r.data.data[0] && r.data.data[0].state) {
                                                                            this.setState({
                                                                                dimmer: false,
                                                                                warehouseState: r.data.data[0].state.filter(e => e.parlour.includes("D"))
                                                                            })
                                                                        }
                                                                    }
                                                                })
                                                        }
                                                    },
                                                    {
                                                        label: "Salony",
                                                        field: "salon",
                                                        render: row => <div style={{position: "relative"}}>
                                                            <div onClick={() => this.setState({modalOpened: {row, warehouse: "salon"}})} className={"states-correction-table-display"}>{row["salon"].correction}</div>
                                                            {DATA_OBJECT["user"]["login"] !== "root" && row["salon"].state < 0 ? 0 : row["salon"].state}

                                                            {row["salon"].blocked ?
                                                                <div style={{
                                                                    marginTop: 3,
                                                                    display: "flex"
                                                                }}>
                                                                    <div style={{
                                                                        fontSize: "0.9em",
                                                                        color: "#006b86",
                                                                        marginRight: 1
                                                                    }}>
                                                                        {TranslationController.translate("Zablk.")} (System):
                                                                    </div>

                                                                    <div style={{
                                                                        fontSize: "0.8em",
                                                                        color: "#575757",
                                                                        fontWeight: 600
                                                                    }}>
                                                                        {row["salon"].blocked}
                                                                    </div>
                                                                </div>
                                                                : <React.Fragment/>
                                                            }

                                                            {row["salon"].frontendBlocked ?
                                                                <div style={{
                                                                    marginTop: 3,
                                                                    display: "flex"
                                                                }}>
                                                                    <div style={{
                                                                        fontSize: "0.9em",
                                                                        color: "#006b86",
                                                                        marginRight: 1
                                                                    }}>
                                                                        {TranslationController.translate("Zablk.")} (Frontend):
                                                                    </div>

                                                                    <div style={{
                                                                        fontSize: "0.8em",
                                                                        color: "#575757",
                                                                        fontWeight: 600
                                                                    }}>
                                                                        {row["salon"].frontendBlocked}
                                                                    </div>
                                                                </div>
                                                                : <React.Fragment/>
                                                            }
                                                        </div>,
                                                        filters: [
                                                            {label: "Więcej niż", condition: "greater", type: "text", forceField: "qty", objectField: "SALON"},
                                                            {label: "Mniej niż", condition: "less", type: "text", forceField: "qty", objectField: "SALON"},
                                                            {label: "Pomiędzy", condition: "between", type: "between", forceField: "qty", objectField: "SALON"}
                                                        ],
                                                        onClick: row => {
                                                            if (this.state.modalOpened) {
                                                                return false
                                                            }

                                                            setTimeout(() => {
                                                                this.setState({
                                                                    dimmer: true
                                                                })

                                                                api().post("/api/states/getByIndex", {index: [{sku: row.sku}]})
                                                                    .then(r => {
                                                                        if (r.data) {
                                                                            if (r.data.data[0] && r.data.data[0].state) {
                                                                                this.setState({
                                                                                    dimmer: false,
                                                                                    warehouseState: r.data.data[0].state.filter(e => !["D", "K99", "157", "N32", "OUT"].includes(e.parlour))
                                                                                })
                                                                            }
                                                                        }
                                                                    })
                                                            }, 150)
                                                        }
                                                    },
                                                    {
                                                        label: this.state.v2 ? "S99" : "X99",
                                                        field: this.state.v2 ? "s99" : "x99",
                                                        render: row => {
                                                            const warehouseId = this.state.v2 ? "s99" : "x99"
                                                            return <div style={{ position: "relative" }}>
                                                                <div onClick={() => this.setState({ modalOpened: { row, warehouse: warehouseId } })} className={"states-correction-table-display"}>{row[warehouseId].correction}</div>
                                                                {DATA_OBJECT["user"]["login"] !== "root" && row[warehouseId].state < 0 ? 0 : row[warehouseId].state}

                                                                {row[warehouseId].blocked ?
                                                                    <div style={{
                                                                        marginTop: 3,
                                                                        display: "flex"
                                                                    }}>
                                                                        <div style={{
                                                                            fontSize: "0.9em",
                                                                            color: "#006b86",
                                                                            marginRight: 1
                                                                        }}>
                                                                            {TranslationController.translate("Zablk.")} (System):
                                                                        </div>

                                                                        <div style={{
                                                                            fontSize: "0.8em",
                                                                            color: "#575757",
                                                                            fontWeight: 600
                                                                        }}>
                                                                            {row[warehouseId].blocked}
                                                                        </div>
                                                                    </div>
                                                                    : <React.Fragment />}

                                                                {row[warehouseId].frontendBlocked ?
                                                                    <div style={{
                                                                        marginTop: 3,
                                                                        display: "flex"
                                                                    }}>
                                                                        <div style={{
                                                                            fontSize: "0.9em",
                                                                            color: "#006b86",
                                                                            marginRight: 1
                                                                        }}>
                                                                            {TranslationController.translate("Zablk.")} (Frontend):
                                                                        </div>

                                                                        <div style={{
                                                                            fontSize: "0.8em",
                                                                            color: "#575757",
                                                                            fontWeight: 600
                                                                        }}>
                                                                            {row[warehouseId].frontendBlocked}
                                                                        </div>
                                                                    </div>
                                                                    : <React.Fragment />}
                                                            </div>;
                                                        },
                                                        filters: [
                                                            {label: "Więcej niż", condition: "greater", type: "text", forceField: "qty", objectField: this.state.v2 ? "S99" : "X99"},
                                                            {label: "Mniej niż", condition: "less", type: "text", forceField: "qty", objectField: this.state.v2 ? "S99" : "X99"},
                                                            {label: "Pomiędzy", condition: "between", type: "between", forceField: "qty", objectField: this.state.v2 ? "S99" : "X99"}
                                                        ],
                                                        onClick: row => {
                                                            if (this.state.modalOpened) {
                                                                return false
                                                            }

                                                            setTimeout(() => {
                                                                this.setState({
                                                                    dimmer: true
                                                                })

                                                                api().post("/api/states/getByIndex", {index: [{sku: row.sku}]})
                                                                    .then(r => {
                                                                        if (r.data) {
                                                                            if (r.data.data[0] && r.data.data[0].state) {
                                                                                this.setState({
                                                                                    dimmer: false,
                                                                                    warehouseState: r.data.data[0].state.filter(e => e.parlour === this.state.v2 ? "S99" : "X99")
                                                                                })
                                                                            }
                                                                        }
                                                                    })
                                                            }, 150)
                                                        }
                                                    },
                                                    {
                                                        label: "PRIB",
                                                        field: "prib",
                                                        render: row => <div style={{position: "relative"}}>
                                                            <div onClick={() => this.setState({modalOpened: {row, warehouse: "prib"}})} className={"states-correction-table-display"}>{row["prib"].correction}</div>
                                                            {DATA_OBJECT["user"]["login"] !== "root" && row["prib"].state < 0 ? 0 : row["prib"].state}

                                                            {row["prib"].blocked ?
                                                                <div style={{
                                                                    marginTop: 3,
                                                                    display: "flex"
                                                                }}>
                                                                    <div style={{
                                                                        fontSize: "0.9em",
                                                                        color: "#006b86",
                                                                        marginRight: 1
                                                                    }}>
                                                                        {TranslationController.translate("Zablk.")} (System):
                                                                    </div>

                                                                    <div style={{
                                                                        fontSize: "0.8em",
                                                                        color: "#575757",
                                                                        fontWeight: 600
                                                                    }}>
                                                                        {row["prib"].blocked}
                                                                    </div>
                                                                </div>
                                                                : <React.Fragment/>
                                                            }

                                                            {row["prib"].frontendBlocked ?
                                                                <div style={{
                                                                    marginTop: 3,
                                                                    display: "flex"
                                                                }}>
                                                                    <div style={{
                                                                        fontSize: "0.9em",
                                                                        color: "#006b86",
                                                                        marginRight: 1
                                                                    }}>
                                                                        {TranslationController.translate("Zablk.")} (Frontend):
                                                                    </div>

                                                                    <div style={{
                                                                        fontSize: "0.8em",
                                                                        color: "#575757",
                                                                        fontWeight: 600
                                                                    }}>
                                                                        {row["prib"].frontendBlocked}
                                                                    </div>
                                                                </div>
                                                                : <React.Fragment/>
                                                            }
                                                        </div>,
                                                        filters: [
                                                            {label: "Więcej niż", condition: "greater", type: "text", forceField: "qty", objectField: "prib"},
                                                            {label: "Mniej niż", condition: "less", type: "text", forceField: "qty", objectField: "prib"},
                                                            {label: "Pomiędzy", condition: "between", type: "between", forceField: "qty", objectField: "prib"}
                                                        ],
                                                        onClick: row => {
                                                            if (this.state.modalOpened) {
                                                                return false
                                                            }

                                                            setTimeout(() => {
                                                                this.setState({
                                                                    dimmer: true
                                                                })

                                                                api().post("/api/states/getByIndex", {index: [{sku: row.sku}]})
                                                                    .then(r => {
                                                                        if (r.data) {
                                                                            if (r.data.data[0] && r.data.data[0].state) {
                                                                                this.setState({
                                                                                    dimmer: false,
                                                                                    warehouseState: r.data.data[0].state.filter(e => e.parlour === "prib")
                                                                                })
                                                                            }
                                                                        }
                                                                    })
                                                            }, 150)
                                                        }
                                                    },
                                                    {
                                                        label: "UKR",
                                                        field: "ukr",
                                                        render: row => <div style={{position: "relative"}}>
                                                            <div onClick={() => this.setState({modalOpened: {row, warehouse: "ukr"}})} className={"states-correction-table-display"}>{row["ukr"].correction}</div>
                                                            {DATA_OBJECT["user"]["login"] !== "root" && row["ukr"].state < 0 ? 0 : row["ukr"].state}

                                                            {row["ukr"].blocked ?
                                                                <div style={{
                                                                    marginTop: 3,
                                                                    display: "flex"
                                                                }}>
                                                                    <div style={{
                                                                        fontSize: "0.9em",
                                                                        color: "#006b86",
                                                                        marginRight: 1
                                                                    }}>
                                                                        {TranslationController.translate("Zablk.")} (System):
                                                                    </div>

                                                                    <div style={{
                                                                        fontSize: "0.8em",
                                                                        color: "#575757",
                                                                        fontWeight: 600
                                                                    }}>
                                                                        {row["ukr"].blocked}
                                                                    </div>
                                                                </div>
                                                                : <React.Fragment/>
                                                            }

                                                            {row["ukr"].frontendBlocked ?
                                                                <div style={{
                                                                    marginTop: 3,
                                                                    display: "flex"
                                                                }}>
                                                                    <div style={{
                                                                        fontSize: "0.9em",
                                                                        color: "#006b86",
                                                                        marginRight: 1
                                                                    }}>
                                                                        {TranslationController.translate("Zablk.")} (Frontend):
                                                                    </div>

                                                                    <div style={{
                                                                        fontSize: "0.8em",
                                                                        color: "#575757",
                                                                        fontWeight: 600
                                                                    }}>
                                                                        {row["ukr"].frontendBlocked}
                                                                    </div>
                                                                </div>
                                                                : <React.Fragment/>
                                                            }
                                                        </div>,
                                                        filters: [
                                                            {label: "Więcej niż", condition: "greater", type: "text", forceField: "qty", objectField: "ukr"},
                                                            {label: "Mniej niż", condition: "less", type: "text", forceField: "qty", objectField: "ukr"},
                                                            {label: "Pomiędzy", condition: "between", type: "between", forceField: "qty", objectField: "ukr"}
                                                        ],
                                                        onClick: row => {
                                                            if (this.state.modalOpened) {
                                                                return false
                                                            }

                                                            setTimeout(() => {
                                                                this.setState({
                                                                    dimmer: true
                                                                })

                                                                api().post("/api/states/getByIndex", {index: [{sku: row.sku}]})
                                                                    .then(r => {
                                                                        if (r.data) {
                                                                            if (r.data.data[0] && r.data.data[0].state) {
                                                                                this.setState({
                                                                                    dimmer: false,
                                                                                    warehouseState: r.data.data[0].state.filter(e => e.parlour === "ukr")
                                                                                })
                                                                            }
                                                                        }
                                                                    })
                                                            }, 150)
                                                        }
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div className={"order-current-completation-present"}>
                                            <div className={"cart-title"}>
                                                <Icon name={"sync"}/>

                                                {TranslationController.translate("Dostępność towaru")}
                                            </div>

                                            <div style={{height: 100}}></div>
                                            <div style={{
                                                position: "relative",
                                                top: -40
                                            }}>
                                                <Loader
                                                    active
                                                    content={TranslationController.translate("Pobieranie stanów magazynowych...")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </React.Fragment>
                        }
                    </Segment>
                </div>

                {this.state.warehouseState &&
                <Modal dimmer={"inverted"} size={"medium"} open={this.state.warehouseState} onClose={() => this.setState({warehouseState: false})}>
                    <Modal.Header>{TranslationController.translate("Stan magazynowy")}</Modal.Header>

                    <Modal.Content>
                        <Table compact>
                            <Table.Body>
                                {this.state.warehouseState.map((item, key) => {
                                    const state = item.state[0];

                                    return (
                                        <Table.Row key={key}>
                                            <Table.Cell width={1}>
                                                <div style={{
                                                    color: "#787878",
                                                }}>
                                                    {item.parlour}
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell width={3}>
                                                <div style={{
                                                    color: "#787878",
                                                }}>
                                                    {state.sku}
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell width={1}>
                                                <div>
                                                    {state.qty}
                                                </div>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table>
                    </Modal.Content>
                </Modal>
                }

                {this.state.invoiceCorrection &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.invoiceCorrection} onClose={() => this.setState({invoiceCorrection: false})}>
                        <Modal.Header>{TranslationController.translate("Wystaw koretkę")}</Modal.Header>

                        <Modal.Content>
                            <div>
                                <Message
                                    icon='inbox'
                                    header='Nowa korekta'
                                    info
                                />
                            </div>

                            <UITable
                                data={this.state.cartData}
                                columns={[
                                    {
                                        label: "Zamówienie",
                                        field: "sku",
                                        width: 1,
                                        render: row => this.props.orderId
                                    },
                                    {
                                        label: "SKU",
                                        field: "sku",
                                        width: 1
                                    },
                                    {
                                        label: "Zdjęcie",
                                        field: "id",
                                        width: 1,
                                        center: true,
                                        render: row => (
                                            <div style={{
                                                cursor: "pointer"
                                            }} onClick={() => {
                                                if (!row.image) return false

                                                this.setState({
                                                    imageModal: `${row.image}`
                                                })
                                            }}>
                                                {row.image && !this.props.noImages ?
                                                    <img
                                                        style={{
                                                            width: 60
                                                        }}
                                                        src={`${row.image}`}
                                                        alt=""
                                                    /> : "---"}
                                            </div>
                                        )
                                    },
                                    {
                                        label: "Produkt",
                                        field: "name",
                                        width: 1,
                                        render: row => row.name || row.sku
                                    },
                                    {
                                        label: "Cena jedn.",
                                        field: "originalPrice",
                                        width: 1,
                                        center: true,
                                        render: row => <div className={"es-order-priceBox"}>
                                            {row.finalQtyPrice ? parseFloat(row.finalQtyPrice / row.qty).toFixed(2) : "---"}
                                            <span>{this.props.currency || getCurrencyString()}</span>
                                        </div>
                                    },
                                    {
                                        label: "Ilość",
                                        field: "qty",
                                        width: 1,
                                        center: true,
                                        render: row => <div className={"es-order-priceBox"}>{parseFloat(row.qty)}</div>
                                    },
                                    {
                                        label: "Wartość",
                                        field: "price",
                                        width: 1,
                                        center: true,
                                        render: row => <div
                                            className={"es-order-priceBox"}>{row.finalQtyPrice ? parseFloat((row.finalQtyPrice)).toFixed(2) : "---"}
                                            <span>{this.props.currency || getCurrencyString()}</span></div>
                                    },
                                    {
                                        label: "Do korekty",
                                        field: "toCorrection",
                                        width: 1,
                                        center: true,
                                        editable: true,
                                        editableOptions: {
                                            type: "number"
                                        },
                                        onEditableChange: (value, row) => {
                                            if (Number.isNaN(value)) {
                                                row.toCorrection = 1;
                                            } else {
                                                if (value > 0) {
                                                    row.toCorrection = value;
                                                } else {
                                                    row.toCorrection = 1;
                                                }
                                            }
                                        },
                                        onEditableConfirm: (row, tableData) => {
                                            const clickedElement = document.activeElement;

                                            if (clickedElement) {
                                                if (row.toCorrection > 0) {
                                                    clickedElement.parentElement.parentElement.parentElement.style.backgroundColor = "#bfffc5";
                                                } else {
                                                    clickedElement.parentElement.parentElement.parentElement.style.backgroundColor = null;
                                                }
                                            }

                                            this.forceUpdate();
                                        },
                                        render: row => <div
                                            className={"es-order-priceBox"}
                                        >{row.toCorrection}</div>
                                    },
                                ]}
                            />
                            {(this.state.previouslyCorrections && this.state.previouslyCorrections.length) ?
                                <React.Fragment>
                                    <div>
                                        <Message
                                            icon='inbox'
                                            header='Towary uwzględnione w poprzednich korektach'
                                            info
                                        />
                                    </div>

                                    <UITable
                                        data={this.state.previouslyCorrections}
                                        columns={[
                                            {
                                                label: "Nr korekty",
                                                field: "invoiceNumber",
                                                center: true,
                                                width: 1,
                                            },
                                            {
                                                label: "Towary w korekcie",
                                                field: "",
                                                width: 3,
                                                render: (row) => (
                                                    <div>
                                                        <Table celled>
                                                            <Table.Header>
                                                                <Table.Row>
                                                                    <Table.HeaderCell>SKU</Table.HeaderCell>
                                                                    <Table.HeaderCell>Zdjęcie</Table.HeaderCell>
                                                                    <Table.HeaderCell>Nazwa</Table.HeaderCell>
                                                                    <Table.HeaderCell>Cena jedn.</Table.HeaderCell>
                                                                    <Table.HeaderCell>Ilość</Table.HeaderCell>
                                                                    <Table.HeaderCell>Wartość</Table.HeaderCell>
                                                                </Table.Row>
                                                            </Table.Header>

                                                            <Table.Body>
                                                                {row.correctionItems && row.correctionItems.length && row.correctionItems.map((item, k) => {
                                                                    return (
                                                                        <Table.Row
                                                                            key={k}
                                                                        >
                                                                            <Table.Cell>{item.sku}</Table.Cell>
                                                                            <Table.Cell>
                                                                                <div style={{
                                                                                    cursor: "pointer"
                                                                                }} onClick={() => {
                                                                                    if (!item.image) return false

                                                                                    this.setState({
                                                                                        imageModal: `${item.image}`
                                                                                    })
                                                                                }}>
                                                                                    {item.image && !this.props.noImages ?
                                                                                        <img
                                                                                            style={{
                                                                                                width: 60
                                                                                            }}
                                                                                            src={`${item.image}`}
                                                                                            alt=""
                                                                                        /> : "---"}
                                                                                </div>
                                                                            </Table.Cell>
                                                                            <Table.Cell>{item.name}</Table.Cell>
                                                                            <Table.Cell>
                                                                                <div className={"es-order-priceBox"}>
                                                                                    {item.finalQtyPrice ? parseFloat(item.finalQtyPrice / item.qty).toFixed(2) : "---"} <span>{this.props.currency || getCurrencyString()}</span>
                                                                                </div>
                                                                            </Table.Cell>
                                                                            <Table.Cell>
                                                                                <div className={"es-order-priceBox"}>{parseFloat(item.toCorrection)}</div>
                                                                            </Table.Cell>
                                                                            <Table.Cell>
                                                                                <div className={"es-order-priceBox"}>{item.finalQtyPrice ? parseFloat((item.finalQtyPrice)).toFixed(2) : "---"} <span>{this.props.currency || getCurrencyString()}</span></div>
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                    )
                                                                })}
                                                            </Table.Body>
                                                        </Table>
                                                    </div>
                                                )
                                            }
                                        ]}
                                    />
                                </React.Fragment> : <React.Fragment/>
                            }
                            <Form>
                                <TextArea
                                    rows={2}
                                    placeholder={TranslationController.translate("Opis...")}
                                    onChange={(e) => {
                                        this.setState({
                                            correctionDescription: e.target.value,
                                        })
                                    }}
                                />
                            </Form>
                            <div>
                                <Button
                                    positive={true}
                                    style={{
                                        marginTop: 5,
                                        marginLeft: 5
                                    }}
                                    onClick={() => {
                                        Confirm("Czy na pewno chcesz wstawić nową korektę?", "", () => {
                                            this.state.cartData.map(item => {
                                                item.toCorrection = item.qty;
                                            })

                                            api().post("/api/order/createInvoiceCorrection", {
                                                orderId: this.props.orderId,
                                                cartData: this.state.cartData,
                                                description: this.state.correctionDescription,
                                                userId: DATA_OBJECT["user"]["login"]
                                            })
                                                .then((r) => {
                                                    if (r.data.error) {
                                                        return SnackBar(r.data.message, "error")
                                                    }

                                                    this.props.refresh();
                                                    this.forceUpdate();

                                                    SnackBar("Wygenerowano nową fakturę.");
                                                })
                                        })
                                    }}
                                >
                                    {TranslationController.translate("Wystaw korektę na wszystko")}
                                </Button>

                                <Button
                                    positive={true}
                                    style={{
                                        marginLeft: 5
                                    }}
                                    disabled={this.state.cartData.some(item => item.toCorrection > 0) === false}
                                    onClick={() => {
                                        Confirm("Czy na pewno chcesz wstawić nową korektę?", "", () => {
                                            api().post("/api/order/createInvoiceCorrection", {
                                                orderId: this.props.orderId,
                                                cartData: this.state.cartData,
                                                description: this.state.correctionDescription,
                                                userId: DATA_OBJECT["user"]["login"]
                                            })
                                                .then((r) => {
                                                    if (r.data.error) {
                                                        return SnackBar(r.data.message, "error")
                                                    }

                                                    this.props.refresh();
                                                    this.forceUpdate();

                                                    SnackBar("Wygenerowano nową fakturę.");
                                                })
                                        })
                                    }}
                                >
                                    {TranslationController.translate("Wystaw korektę na wybrane")}
                                </Button>
                            </div>
                        </Modal.Content>
                    </Modal>
                }

                {this.state.dimmer &&
                    <Dimmer active inverted style={{
                        position: "fixed",
                        zIndex: 99999
                    }}>
                        <Loader size='medium'>{TranslationController.translate("Proszę czekać... trwa synchronizacja.")}</Loader>
                    </Dimmer>
                }
            </React.Fragment>
        )
    }
}
